/**============================================================
*   [HeaderNavigation]
*   description : グローバルナビの処理を記述
============================================================*/
import {createApp, onMounted, reactive, watch} from 'vue';
import { createMedia } from 'vue-use-media';

document.addEventListener('DOMContentLoaded', function() {
  const header = document.getElementById('header-navigation');
  if(header){
    const HeaderNavigation = createApp({
      setup() {
        const media = createMedia({
          isDesktop: 'screen and (min-width: 980px)'
        })
        const state = reactive({
          isScrolled: false,
          isGnavOpened: false,
          isStopAnimation: false,
        })

        const resetGnav = () => {
          //アニメーションを切る（切り替え時・ブラウザバック時の対策）
          state.isStopAnimation = true;
          document.documentElement.classList.remove('is-gnav-opened');
          state.isGnavOpened = false;
        }

        const headerScroll = () => {
          let winTop =  window.scrollY || document.documentElement.scrollTop;
          // if(winTop > window.innerHeight){
          if(winTop > 300){
            state.isScrolled = true;
          }else{
            state.isScrolled = false;
          }
        }

        onMounted(() => {

          //初期化
          watch(media, () => {
            resetGnav();
          })

          //スクロール時
          headerScroll();
          window.addEventListener('scroll', headerScroll);

          //キャッシュによる表示のリセット対策
          window.addEventListener('onbeforeunload', () => {}, false);
          window.addEventListener('onunload', () => {}, false);
          window.addEventListener('pageshow', function(event) {
            if (event.persisted) {
              resetGnav();
            }
          });
        })

        //グロナビの開閉（トグル）
        const toggleGnav = function() {
          state.isStopAnimation = false;
          if(state.isGnavOpened){
            state.isGnavOpened = false;
            document.documentElement.classList.remove('is-gnav-opened');
          }else{
            state.isGnavOpened = true;
            document.documentElement.classList.add('is-gnav-opened');
          }
        }

        //グロナビ閉じるのみ
        const closeGnav = function() {
          state.openNum = [];
          state.isGnavOpened = false;
        }

        return {
          media,
          state,
          toggleGnav,
          closeGnav,
          resetGnav,
          headerScroll,
        }
      },
      methods: {
        beforeEnter(el) {
          el.style.height = '0'
        },
        enter(el) {
          el.style.height = el.scrollHeight + 'px'
        },
        beforeLeave(el) {
          el.style.height = el.scrollHeight + 'px'
        },
        leave(el) {
          if(!this.media.isDesktop){
            el.style.height = '0'
          }
        },
      }
    })
    HeaderNavigation.mount('#header-navigation')
  }
})
