/**============================================================
*   [Class] プランリストスライダー
============================================================*/
export default class PlanListSlider {
  constructor(
    target,
    key,
  )
  {
    this.swiperSlide = target;
    this.swiperKey = key;
    this.swiperSlide.classList.remove('swiper-disabled');
    this.swiperSlide.classList.add('swiper-plan-list-'+ this.swiperKey);
    this.breakSmNum = 782;
    this.breakMdNum = 980;

    let swiperSlides = this.swiperSlide.getElementsByClassName('swiper-slide');
    this.swiperSlideItemNum = swiperSlides.length * 2;
    let slidable = swiperSlides.length > 1;

    //スライダー実行タイミング分岐設定
    slidable = this.swiperSlide.dataset.sliderEnable == 'sm-down' ? true : false;
    let slidableMd = this.swiperSlide.dataset.sliderEnable == 'md-down' ? true : false;

    //[pagination] バナーの時DOM構造変更（デフォルトは記事リスト）
    const nextEl = '.swiper-plan-list-'+ key + ' .swiper-button-next';
    const prevEl = '.swiper-plan-list-'+ key + ' .swiper-button-prev';

    this.initSwiper = () => {
      this.mySwiper = new Swiper(this.swiperSlide, {
        slidesPerView: 'auto',
        spaceBetween: 0,
        watchOverflow: slidable,
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          768: {
            spaceBetween: 34,
          },
        },
      });
    };

    const mqlSliderMd = window.matchMedia('(min-width: 980px)');
    const mqlSliderMdCheck = () => {
      if(window.innerWidth > 980) {
        if(this.mySwiper) {
          this.mySwiper.destroy(false,true);
          this.mySwiper = '';
        }
      }else{
        this.initSwiper();
      }
    }

    //slidableMdがtrueだった時にタブレットサイズの判定が入る
    if(slidableMd){
      mqlSliderMdCheck(mqlSliderMd);
      mqlSliderMd.addEventListener('change', mqlSliderMdCheck, false);
    }else {
      this.initSwiper();
    }
  }
}
