/**============================================================
*   [contactform7Customize]
*   description : お問い合わせフォームのエラー文言処理
============================================================*/
const inquiryForm = document.getElementById('contactForm');

if (inquiryForm) {
  jQuery(document).on('wpcf7invalid', function () {
    jQuery.ajax().always(function () {

      // 既存のエラー文言を削除
      if (jQuery('.c-text-error').length) {
        jQuery('.c-text-error').each(function (index, prev_error) {
          jQuery(prev_error).remove();
        });
      }

      jQuery('.wpcf7-form-control-wrap').each(function (index, el) {
        if (jQuery(el).find('.wpcf7-not-valid-tip').length) {
          var errorTips = jQuery(el).find('.wpcf7-not-valid-tip');
          // エラー文言用classを追加
          errorTips.addClass('c-text-error');

          // エラー文言表示位置を変更
          jQuery(el).before(errorTips);

          // 前の要素のエラー文言と重複していた場合
          jQuery('.wpcf7-not-valid-tip').each(function (index_ch, el_ch) {
            if (jQuery(el_ch).prev().text() == jQuery(el_ch).text()) {
              jQuery(el_ch).remove();
            }
          });

        }
      });

      // エラー文言表示時のスクロール処理
      const position = jQuery('.wpcf7-not-valid-tip:first').closest('tr').offset().top;
      const header = jQuery('#js-header').outerHeight();
      jQuery('html, body').animate({
        scrollTop: position - header
      }, 700);
    });
  });
  jQuery(document).ready(function () {
    jQuery('.wpcf7-form-control-wrap').each(function (index, el) {
      jQuery(el).find('.wpcf7-list-item > label > input').after('<span class="c-form-checkbox__icon"></span>');
    });
  });
  document.addEventListener('wpcf7mailsent', function () {
    location = '/contact/thanks/';
  }, false);

  // selectのvalueが空の時にテキスト色を変更する処理
  const selectIsEmpty = () => {
    if (document.getElementsByTagName('select').length > 0) {
      const selects = document.getElementsByTagName('select');
      const changeEmptyClass = (node) => {
        if (node.value !== '') {
          node.classList.remove('is-value-empty');
        } else {
          node.classList.add('is-value-empty');
        }
      }
      [...selects].forEach(select => {
        changeEmptyClass(select);
        select.addEventListener('change', (e) => {
          changeEmptyClass(e.target);
        })
      })
      //yubinbango対策
      //p-postal-codeのkeyupイベントで値が変更されるため、keyupの200ms後に判定
      const yubinbangoNode = document.getElementsByClassName('h-adr');
      [...yubinbangoNode].forEach(yubin => {
        const postCodes = yubin.getElementsByClassName('p-postal-code');
        const regionSelect = yubin.querySelector('.p-region');

        [...postCodes].forEach(postCode => {
          changeEmptyClass(regionSelect);
          postCode.addEventListener('keyup', () => {
            var postKeyupTime = setInterval(function () {
              changeEmptyClass(regionSelect);
              clearInterval(postKeyupTime);
            }, 200);
          })
        })
      })
    }
  }
  document.addEventListener('DOMContentLoaded', selectIsEmpty, false);

  // チェックボックスの状態に応じて送信ボタンのdisabled属性を更新
  const agreeChange = () => {
    const checkBox = document.getElementById('agree');
    const submitButton = document.querySelector('.c-form-submit');

    checkBox.addEventListener('change', function () {
      submitButton.disabled = !this.checked;
    });
  }

  document.addEventListener('DOMContentLoaded', agreeChange);

  // 送信ローダーの調整 連続送信防止
  const submitElements = document.getElementsByClassName('c-form-submit');
  if (submitElements.length > 0) {
    const submitInput = document.getElementsByClassName('wpcf7-submit')[0];
    const submitValue = submitInput.value;
    const submitElement = submitElements[0];

    submitElement.addEventListener('click', function () {
      submitElement.classList.add('wpcf7-active');
      submitInput.value = '送信中…';
    });

    document.addEventListener('wpcf7invalid', function () {
      submitElement.classList.remove('wpcf7-active');
      submitInput.value = submitValue;
    });
  }

}
