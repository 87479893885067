/**============================================================
* [observerShowObj]
* description : observerで表示するオブジェクト
============================================================*/
export default class observerShowObj {
  constructor(target,threshold=0) {
    this.headerH = document.getElementById('header-navigation').offsetHeight;
    this.target = target;
    this.margin = target.dataset.rootBottomMargin ? '0px 0px ' + target.dataset.rootBottomMargin + ' 0px' : '0px 0px -20% 0px';
    this.threshold = target.dataset.threshold ? Number(target.dataset.threshold) : threshold;

    //表示タイミング
    const handleObserveStart = (elems) => {
      elems.forEach((elem) => {
        if (elem.isIntersecting) {
          this.target.classList.add('is-show');
        }
      });
    }
    const observerOptionsStart = {
      root: null,
      rootMargin: this.margin,
      threshold: this.threshold
    };
    const observerStart = new IntersectionObserver(handleObserveStart, observerOptionsStart);

    observerStart.observe(this.target);

  }
}
