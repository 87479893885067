//import
import './build/setVers.js';
import './build/anchor.js';
import './build/pagetop.js';
import './build/HeaderNavigation.js';
import './build/contactForm.js';

/**============================================================
*   [viewport] 375px以下画面は縮小
============================================================*/
(function () {
  const viewport = document.querySelector('meta[name="viewport"]');
  function switchViewport() {
    const value =
      window.outerWidth >= 375
        ? 'width=device-width,initial-scale=1'
        : 'width=375';
    if (viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value);
    }
  }
  const mqlViewport = window.matchMedia('(min-width: 375px)');
  switchViewport(mqlViewport);
  mqlViewport.addEventListener('change', switchViewport, false);
})();

/**============================================================
*   [Animation] observerでアニメーション用クラス付与
*   target : .p-animation-observer
============================================================*/
import observerShowObj from './build/observerShowObj';
const observerElem = document.getElementsByClassName('p-animation-observer');
const createObserveObj = () => {
  if (!observerElem.length) return;
  for (let i = 0; i < observerElem.length; i++) {
    new observerShowObj(observerElem[i]);
  }
}
document.addEventListener('DOMContentLoaded', createObserveObj, false);

/**============================================================
*   [lottie]
============================================================*/
import lottieImage from './build/lottieImage';
const lottieLoader = document.getElementsByClassName('js-lottie');
const observeLottieAnimation = () => {
    if (!lottieLoader.length) return;
    for (let i = 0; i < lottieLoader.length; i++) {
      new lottieImage(lottieLoader[i]);
    }
}
document.addEventListener('DOMContentLoaded', observeLottieAnimation, false);

/**============================================================
*   [mouse] マウスストーカー
============================================================*/
const mouseCursor = document.getElementById('p-mouse-stalker');//カーソル要素
const mouseTargets = document.querySelectorAll(".p-mouse-stalker-item");//ターゲット要素

document.addEventListener('mousemove', function (e) {
  mouseCursor.style.translate = e.clientX + 'px ' + e.clientY + 'px';
});
//ターゲットマウスオーバー時にクラス付与
mouseTargets.forEach(item => {
  item.addEventListener('mouseenter', (e) => {
    if(e.target.dataset.stalkerMode) {
      if(e.target.dataset.stalkerMode) mouseCursor.classList.add(e.target.dataset.stalkerMode);
    }else{
      mouseCursor.classList.add('is-hover');
    }
  });
  item.addEventListener('mouseleave', (e) => {
    if(e.target.dataset.stalkerMode) {
      mouseCursor.classList.remove(e.target.dataset.stalkerMode);
      mouseCursor.classList.add('is-hover');
    }else{
      mouseCursor.classList.remove('is-hover');
    }
  });
})

/**============================================================
*   [form] select
*   description : selectのvalueが空の時にテキスト色を変更する処理を記述
============================================================*/
const selectIsEmpty = () => {
  if(document.getElementsByTagName('select').length > 0){
    const selects = document.getElementsByTagName('select');
    [...selects].forEach(select => {
      if (select.value !== '') {
        select.classList.remove('is-value-empty');
      }else{
        select.classList.add('is-value-empty');
      }
      select.addEventListener('change', (e) => {
        if (e.target.value !== '') {
          e.target.classList.remove('is-value-empty');
        }else{
          e.target.classList.add('is-value-empty');
        }
      })
    })
  }
}

document.addEventListener('DOMContentLoaded', selectIsEmpty, false);

/**============================================================
*   [MicroModal]
============================================================*/
import MicroModal from 'micromodal';
MicroModal.init({
  awaitOpenAnimation: true,
  awaitCloseAnimation: true,
  disableScroll: true,
  onShow: ()=>{
    document.documentElement.classList.add('is-modal-opened');
    lenis.stop();
  },
  onClose: ()=>{
    document.documentElement.classList.remove('is-modal-opened');
    lenis.start();
  }
});

/**============================================================
*   [共通] サービスリストスライダー
*   target : .p-service-list-slider
============================================================*/
import ServiceListSlider from './build/ServiceListSlider';
const ServiceListSliderElem = document.getElementsByClassName('p-service-list-slider');
const createServiceListSliderObj = () => {
  if (!ServiceListSliderElem.length) return;
  for (let i = 0; i < ServiceListSliderElem.length; i++) {
    new ServiceListSlider(ServiceListSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createServiceListSliderObj, false);

/**============================================================
*   [共通] プランリストスライダー
*   target : .p-plan-list-slider
============================================================*/
import PlanListSlider from './build/PlanListSlider';
const PlanListSliderElem = document.getElementsByClassName('p-plan-list-slider');
const createPlanListSliderObj = () => {
  if (!PlanListSliderElem.length) return;
  for (let i = 0; i < PlanListSliderElem.length; i++) {
    new PlanListSlider(PlanListSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createPlanListSliderObj, false);

/**============================================================
*   [共通] 実績リストスライダー
*   target : .p-achievements-list-slider
============================================================*/
import AchievementsListSlider from './build/AchievementsListSlider';
const AchievementsListSliderElem = document.getElementsByClassName('p-achievements-list-slider');
const createAchievementsListSliderObj = () => {
  if (!AchievementsListSliderElem.length) return;
  for (let i = 0; i < AchievementsListSliderElem.length; i++) {
    new AchievementsListSlider(AchievementsListSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createAchievementsListSliderObj, false);

/**============================================================
*   [TOP] メンバーリストスライダー
*   target : .p-page-top__member-slider
============================================================*/
import MemberListSlider from './build/MemberListSlider';
const MemberListSliderElem = document.getElementsByClassName('p-page-top__member-slider');
const createMemberListSliderObj = () => {
  if (!MemberListSliderElem.length) return;
  for (let i = 0; i < MemberListSliderElem.length; i++) {
    new MemberListSlider(MemberListSliderElem[i], i);
  }
}
document.addEventListener('DOMContentLoaded', createMemberListSliderObj, false);

/**============================================================
*   [lenis]
============================================================*/
import Lenis from '@studio-freight/lenis';
import {gsap, Power4} from 'gsap';//, Power2, Power4, Back
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {CustomEase} from 'gsap/CustomEase';
import {MotionPathPlugin} from 'gsap/MotionPathPlugin';
gsap.registerPlugin(ScrollTrigger,CustomEase, MotionPathPlugin, Power4);

const lenis = new Lenis();
lenis.on('scroll', ScrollTrigger.update)

gsap.ticker.add((time)=>{
  lenis.raf(time * 1000)
})
gsap.ticker.lagSmoothing(0);
let gsapMq = gsap.matchMedia();

// ミッションの上パディングの高さが画面のcenterに来た時に捌ける
const getContentTop = (getAll) => {
  let mainContentsH = document.querySelector(".js-main").clientHeight;
  const missionContents = document.querySelector(".js-mission");
  const missionText = document.querySelector('.js-mission-text');
  let contentsHeight = 0;

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  const breakPoint = 768;

  let isMobileSize = (windowWidth <= breakPoint);
  console.log('isMobileSize',isMobileSize,windowWidth);

  if(getAll) {
    if(isMobileSize) {
      console.log('SP',(windowWidth * 181) / 375,window.getComputedStyle(missionText).paddingBottom);
      // contentsHeight = mainContentsH + (windowWidth * 100) / 375;
      // contentsHeight = mainContentsH + missionContents.clientHeight;
      let pb = (parseInt(window.getComputedStyle(missionText).paddingBottom) < (windowWidth * 181) / 375) ? (windowWidth * 181) / 375 : parseInt(window.getComputedStyle(missionText).paddingBottom);
      contentsHeight = mainContentsH + missionContents.clientHeight - pb;
      //100 : 375 = x : windowW
    }else{
      //pc
      if(windowHeight < 830){
        console.log('PC windowHeight830以下',window.getComputedStyle(missionText).paddingBottom);
        mainContentsH = 830;
        // contentsHeight = mainContentsH + (windowWidth * (84 * 2)) / 1600;
        // contentsHeight = mainContentsH + missionContents.clientHeight - (windowWidth * 300) / 1600;//下パディング分をマイナス
        contentsHeight = mainContentsH + missionContents.clientHeight - parseInt(window.getComputedStyle(missionText).paddingBottom);
      }else{
        console.log('PC',window.getComputedStyle(missionText).paddingBottom);
        // contentsHeight = mainContentsH + missionContents.clientHeight / 2;
        // contentsHeight = mainContentsH + missionContents.clientHeight - (windowWidth * 300) / 1600;
        contentsHeight = mainContentsH + missionContents.clientHeight - parseInt(window.getComputedStyle(missionText).paddingBottom);
      }
    }
  }
  return contentsHeight
}
const getContentEndPos = (getAll) => {
  let pos;
  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  const breakPoint = 768;
  let isMobileSize = (windowWidth < breakPoint);

  if(getAll) {
    if(isMobileSize) {
      // pos = 'top';
      pos = 'bottom';
    }else{
      if(windowHeight < 830){
        // pos = 'top';
        pos = 'bottom';
      }else{
        // pos = 'center';
        pos = 'bottom';
      }
    }
  }
  return pos
}

const OPMain = document.getElementById('js-main-op');

const htmlElem = document.getElementsByTagName('html');
if(OPMain){

  const OPshapeSquare = document.getElementById('path-square-op');
  const OPshapePlus = document.getElementById('path-plus-op');
  const OPtitleText = document.getElementById('js-main-title-op');
  // const OPloadingBg = document.getElementById('js-loading-bg'); // ローディング背景

  var images = document.getElementsByTagName('img'); // ページ内の画像取得
  var percent = document.getElementById('js-loading-percent-text'); // パーセントのテキスト部分
  // var gauge = document.getElementById('js-loading-gauge'); // ゲージ
  // var loadingBg = document.getElementById('js-loading-bg'); // ローディング背景
  var loading = document.getElementById('js-loading'); // ローディング要素
  var imgCount = 0;
  var baseCount = 0;
  // var gaugeMax = 400; // ゲージの幅指定
  const dashOffsetSquare = 2000;
  var current;

  htmlElem[0].classList.add('is-scroll-fixed');
  lenis.stop();

  // 画像の読み込み
  for (var i = 0; i < images.length; i++) {
    var img = new Image(); // 画像の作成
    // 画像読み込み完了したとき
    img.onload = function() {
        imgCount += 1;
    }
    // 画像読み込み失敗したとき
    img.onerror = function() {
        imgCount += 1;
    }
    img.src = images[i].src; // 画像にsrcを指定して読み込み開始
  };
  // ローディング処理
  var nowLoading = setInterval(function() {
    window.scrollTo({
      top: 0,
    });
    if(baseCount <= imgCount) { // baseCountがimgCountを追い抜かないようにする
      // 現在の読み込み具合のパーセントを取得
      current = Math.floor(baseCount / images.length * 100);
      // パーセント表示の書き換え
      percent.innerHTML = current;
      // ゲージの変更
      // gauge.style.width = Math.floor(gaugeMax / 100 * current) + 'px';
      if(current < 50) {
        OPshapeSquare.style.strokeDashoffset = dashOffsetSquare - Math.floor(dashOffsetSquare / 100 * current * 2) + 'px';
      }else if(current < 90) {
        OPshapePlus.style.strokeDashoffset = dashOffsetSquare - Math.floor(dashOffsetSquare / 100 * (current / 2) * 2) + 'px';
      }else {
        // OPshapeSquare.style.fill = '#CECECE';
        // OPshapePlus.style.fill = '#CECECE';
        OPshapeSquare.classList.add('is-complete');
        OPshapePlus.classList.add('is-complete');
        OPtitleText.classList.add('is-complete');
        loading.classList.add('is-complete');
      }

      baseCount += 0.5;
      // 全て読み込んだ時
      if(baseCount > (images.length - 1)) {
        var completeTime = setInterval(function() {
          // ローディング要素の非表示
          OPMain.classList.add('is-complete');
          clearInterval(completeTime);
        }, 1000);

        var completeTime2 = setInterval(function() {
          //スクロールスタート
          lenis.start();
          clearInterval(completeTime2);
        }, 2400);

        // ローディングの終了
        clearInterval(nowLoading);
      }
    }
  }, 10);


  //メインロゴ横移動→拡大まで
  const mainTL = gsap.timeline({
    scrollTrigger: {
      trigger: '.js-main',//js-main-title-container
      start: 'top top',//トリガーのy, スクロールのy
      end: () => `${getContentTop(true)} ${getContentEndPos(true)}`,
      pin: true,//アニメーション終わるまで固定
      scrub: true,//連動
      // markers: true,
    }
  })
  const breakPoint = 782;
  gsapMq.add(
    {
      isDesktop: `(min-width: ${breakPoint}px)`,
      isMobile: `(max-width: ${breakPoint - 1}px)`,
      reduceMotion: "(prefers-reduced-motion: reduce)",
    },
    (context) => {
      let { isDesktop, isMobile } = context.conditions;
      mainTL.to('.js-main-title-container', {//アニメーションしたい要素
        keyframes: [
          {
            x: isDesktop ? '37.375%' : '26.6%',
            //PC：50% - 12.625%（ロゴの半分マイナス）404 / 2 = 202 / 1600 * 100 = 12.625（ロゴの半分マイナス） 50 - 12.625 = 37.375
            //SP：175 / 2 = 87.5 / 375 * 100 = 23.33333333%（ロゴの半分マイナス） 50% - 26.66666667
          },
        ]
      })
      .to('.js-main-square', {
        keyframes: [
          {
            scale: () => {
              if(isDesktop){
                return 3.2;
              }else {
                return 4.49;
              }
            },
            // scale: () => {
            //   if(isDesktop){
            //     return 3.78;
            //   }else {
            //     return 4.99;
            //   }
            // },
            //OPロゴからmissionまでの拡大率
            //PC：before:315px、after:1180 3.7866%? or 404 1500あれ？3.45？
            //SP：before:175px、after:873 4.98857143%
            y: isMobile ? '50%' : 0,
          }
        ]
      })
      .to('.js-main-plus, .js-main-title', {
        keyframes: [
          {
            x: '380%',
            scale: 3.45,
          }
        ]
      }, '<')
      .to('.js-main-title-op', {
        keyframes: [
          {
            x: '40%',
          }
        ]
      }, '<')
      .to('#js-main-copy', {
        keyframes: [
          {
            x: '40%',
            opacity: 0,
          }
        ]
      }, '<')
      .to('#path-square path', {
        keyframes: [
          {
            fill: '#151515',
            stroke: '#151515',
            opacity: 0
          }
        ]
      }, '-=0.5')
      .to('.js-main-mission-frame', {//wave
        keyframes: [
          {
            scale: 1,
            opacity: 1,
          },
        ],
      }, '-=0.5')
      .to('#js-main-scroll', {
        keyframes: [
          {
            opacity: 0,
            duration: 0.2
          }
        ]
      }, '-=0.5')

      //実績のタイムライン（十字）
      const achievementsTL = gsap.timeline({
        // repeat: -1,
        scrollTrigger: {
          trigger: '.js-achievements',
          start: 'center 60%',
          end: '95% center',
          // pin: true,//アニメーション終わるまで固定
          // scrub: true,//連動
          // markers: true,
        },
      })
      achievementsTL.to('.js-achievements-plus-1,.js-achievements-plus-2',
        {
          keyframes: [
            {
              scale: 1,
              duration: 1,
              ease: "power4.out",
            },
          ],
        },
      )

      //強み
      const strengthsTL = gsap.timeline({
        scrollTrigger: {
          trigger: '.js-page-top__strengths__body',
          start: 'top top',
          // end: () => `${getStrengthsHeight(true)} 95%`,
          end: () => `bottom 95%`,
          pin: true,//アニメーション終わるまで固定
          scrub: true,//連動
          pinSpacing: false,
          // markers: true,
        },
      })
      strengthsTL.to('.js-page-top__strengths__section',
        {
          keyframes: [
            {
              y: '-50%'//-100%から
            },
          ],
        },
      )

      //[company] TL1：セクションが画面上部に来るまでは少しだけ動かす
      const companyTL = gsap.timeline({
        scrollTrigger: {
          trigger: '.js-company',
          start: 'top bottom',
          end: 'top top',
          scrub: true,
        },
      })
      companyTL.to('.js-company-item-1',
        {
          keyframes: [
            {
              y: isDesktop ? '-70%' : '0%',//100%から
              x: isDesktop ? '0%' : '-70%'//0%から
            },
          ],
        },
      )
      .to('.js-company-item-2',
        {
          keyframes: [
            {
              y: isDesktop ? '-30%' : '0%',//0%から
              x: isDesktop ? '0%' : '-30%'//0%から
            },
          ],
      }, '<')

      //[company] TL2：セクションが画面上部に来たら固定してさらに画像動かす
      const companyTL2 = gsap.timeline({
        scrollTrigger: {
          trigger: '.js-company',
          start: isDesktop ? 'top 105px' : 'top 75px',//headerの70px と隙間調整
          end: 'bottom top',
          pin: true,//アニメーション終わるまで固定
          scrub: true,//連動
          // markers: true,
        },
      })
      companyTL2.to('.js-company-item-1',
        {
          keyframes: [
            {
              y: isDesktop ? '-20%' : '0%',
              x: isDesktop ? '0%' : '-20%'
            },
          ],
        },
      )
      .to('.js-company-item-2',
        {
          keyframes: [
            {
              y: isDesktop ? '-80%' : '0%',
              x: isDesktop ? '0%' : '-80%'
            },
          ],
      }, '<')
      //[company] TL3：TL2が終わりセクションが捌けるまで微妙に動かす
      const companyTL3 = gsap.timeline({
        scrollTrigger: {
          trigger: '.js-company',
          start: 'top 70px',//headerの70px と隙間調整
          end: 'bottom top',
          scrub: true,
        },
      })
      companyTL3.to('.js-company-item-1',
        {
          keyframes: [
            {
              y: '0%',
              x: '0%'
            },
          ],
        },
      )
      .to('.js-company-item-2',
        {
          keyframes: [
            {
              y: isDesktop ? '-120%' : '0%',
              x: isDesktop ? '0%' : '-120%'
            },
          ],
      }, '<')
    }
  )
}
