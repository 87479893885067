/**============================================================
*   [lottieImage]
*   description : lottie要素再生コントロール 属性値で設定
*                 * lottie設定
*                 data-lottie-json      : jsonのfileパス
*                 data-lottie-loop      : true | false
*                 data-lottie-auto-play : true | false
*                 * observer設定
*                 data-lottie-root      : root要素指定
*                 data-lottie-margin    : margin指定
*                 data-lottie-threshold : threshold指定
============================================================*/
import lottie from 'lottie-web';
export default class lottieImage {
  constructor(elem) {
    /* Lottieの設定 */
    this.filePath = elem.dataset.lottieJson;
    this.loop = elem.dataset.lottieLoop ? JSON.parse(elem.dataset.lottieLoop.toLowerCase()) : true;
    this.autoPlay = elem.dataset.lottieAutoPlay ? JSON.parse(elem.dataset.lottieAutoPlay.toLowerCase()) : false;
    this.observer =  elem.dataset.lottieObserver ? JSON.parse(elem.dataset.lottieObserver.toLowerCase()) : true;
    this.animationLoad = lottie.loadAnimation({
        container: elem,
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoPlay,
        path: this.filePath,
    });

    if(this.observer == true) {
      /* observerの設定 */
      this.root = elem.dataset.lottieRoot ? elem.dataset.lottieRoot : null;
      this.rootMargin = elem.dataset.lottieRootMargin ? elem.dataset.lottieRootMargin : '-75% 0px -25%';
      this.threshold = elem.dataset.lottieThreshold ? Number(elem.dataset.lottieThreshold) : 0;

      const handleObserve = (elems) => {
        elems.forEach((elem) => {
          if (elem.isIntersecting) {
            this.scrollAnimationFunc();
          }
        });
      }
      const observerOptions = {
        root: this.lottieRoot,
        rootMargin: this.rootMargin,
        threshold: this.threshold
      };
      const observer = new IntersectionObserver(handleObserve, observerOptions);
      observer.observe(elem);

    }else{
      this.scrollAnimationFunc();
    }

    this.initPlay();
  }
  initPlay() {
    if(this.autoPlay){
      this.animationLoad.play();
    }
  }
  scrollAnimationFunc() {
    if(!this.autoPlay){
      this.autoPlay = true;
      this.animationLoad.play();
    }
  }
}
