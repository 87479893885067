/**============================================================
*   [anchor]
*   description : ページ内リンクの処理を記述
============================================================*/
document.addEventListener('DOMContentLoaded', () => {
  const targetId = window.location.hash.replace('#','');
  const anchorLinks = document.querySelectorAll('a[href*="#"]')
  const anchorLinksArr = Array.prototype.slice.call(anchorLinks);
  let header = document.getElementById('header-navigation');
  let headerHeight = (header) ? header.offsetHeight : 0;

  //headerの高さ算出
  const mqlAnchor = window.matchMedia('(min-width: 782px)');
  function mqlAnchorCheck() {
    headerHeight = (header) ? header.offsetHeight : 0;
  }
  mqlAnchorCheck(mqlAnchor);
  mqlAnchor.addEventListener('change', mqlAnchorCheck, false);

  //ロード時：ハッシュがある場合該当箇所へ移動
  window.addEventListener('load', () => {
    if(targetId){
      const targetElement = document.getElementById(targetId);
      if(targetElement){
        targetElement.scrollIntoView();
        window.scrollBy(0,-headerHeight);
      }
    }
  });

  //アンカークリック時
  anchorLinksArr.forEach(link => {
    link.addEventListener('click', e => {
      const targetId = link.hash;
      const targetElement = document.querySelector(targetId);
      if(targetElement){
        e.preventDefault();
        const targetOffsetTop = window.scrollY + targetElement.getBoundingClientRect().top;
        const totalScrollAmount = targetOffsetTop - headerHeight;
        window.scrollTo({
          top: totalScrollAmount,
          behavior: 'smooth'
        });
      }
    });
  });

});
